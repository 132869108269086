@import "constants";

.fallback-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    // @media (max-height: 1440px) {
	// 	height: 1000px;
	// }
	// @media (max-height: 700px) {
	// 	height: 100%;
	// }
	// @media (max-height: 300px) {
	// 	height: 150px;
	// }

    .info {
        text-align: center;

        h1 {
            color: $orange;
            animation:  blinker 2s linear infinite;
            font-size: 100px;

            @media (max-width: 600px) {
                font-size: 72px;
            }
            @media (max-width: 350px) {
               font-size: 40px;
            }
        }

        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
    }
}