@use "../common.scss" as default;
@import "../constants";

.logo-maker {
    &.logo-maker-container {
        @include default.logo-maker-container
    }
    .top-section {
        @include default.top-section;
    }
    .content-section {
        @include default.content-section-titles;
    }
    .step1 {
        margin: 50px auto 25px;
        max-width: 480px;
        label {
            display: block;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: #6A6A6A;
            border-radius: 4px;
            margin-bottom: 8px;
        }
        input {
            height: 54px;
            width: 100%;
            color: $black;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.07px;
            font-family: $noto;
            padding: 18px 13px;
            margin-bottom: 20px;
            border-radius: 4px;
            border: 1px solid #E7E7E7;
            border-radius: 4px;
            &:focus {
                outline: none;
                border-color: $light-blue;
                transition: $transition;
            }
        }
    }
    .next {
        @include default.next;
    }
}

@media (max-width: 600px) {
    .logo-maker .step1 {
        margin: 35px auto 5px;
        max-width: 100%;
    }
}
